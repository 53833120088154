import { render, staticRenderFns } from "./MatchesDatatable.vue?vue&type=template&id=26c1eaec&scoped=true&"
import script from "./MatchesDatatable.vue?vue&type=script&lang=js&"
export * from "./MatchesDatatable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26c1eaec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/bonomma/SW_DEV/ktrack/ksport-ktrackapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26c1eaec')) {
      api.createRecord('26c1eaec', component.options)
    } else {
      api.reload('26c1eaec', component.options)
    }
    module.hot.accept("./MatchesDatatable.vue?vue&type=template&id=26c1eaec&scoped=true&", function () {
      api.rerender('26c1eaec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/datatable/MatchesDatatable.vue"
export default component.exports