var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "Matchlist:" }, [
      _c(
        "div",
        { staticClass: "Matchlist:container" },
        [
          _c("div", { staticClass: "Matchlist:container40" }, [
            _c("div", { staticClass: "Matchlist:box poststable:" }, [
              _c(
                "div",
                { staticClass: "Matchlist:box-wrap" },
                [_c("MatchesDatatable")],
                1
              )
            ])
          ]),
          _c("Menu")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }