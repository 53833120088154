var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "v-card",
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.dialogRestart,
                callback: function($$v) {
                  _vm.dialogRestart = $$v
                },
                expression: "dialogRestart"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Are you sure you want to restart this match?"),
                    _c("br"),
                    _vm._v(" All client connections will be closed")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeRestart }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.restartMatchConfirm }
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.dialogRemove,
                callback: function($$v) {
                  _vm.dialogRemove = $$v
                },
                expression: "dialogRemove"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Are you sure you want to remove this match?"),
                    _c("br"),
                    _vm._v(" All client connections will be closed")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeRemove }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.removeMatchConfirm }
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.dialogDemo,
                callback: function($$v) {
                  _vm.dialogDemo = $$v
                },
                expression: "dialogDemo"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Are you sure you want restart demo data?"),
                    _c("br"),
                    _vm._v(" Remember to lauch it after UTE-VIT match restart")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeDemo }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.rundemoConfirm }
                        },
                        [_vm._v("OK")]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              headers: _vm.outerHeaders,
              items: _vm.matches,
              "single-expand": _vm.singleExpand,
              expanded: _vm.expanded,
              "items-per-page": 20,
              "item-key": "id",
              "show-expand": "",
              "hide-default-footer": ""
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { id: "toolbar" } },
                      [
                        _c("v-toolbar-title", [
                          _c("span", { staticClass: "px-3" }, [
                            _vm._v("Active Matches - " + _vm._s(_vm.now))
                          ])
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-menu",
                          {
                            attrs: { top: "", "close-on-click": true },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "primary",
                                              dark: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-menu")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { rounded: "", color: "primary" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.expandAll()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("Expand")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.collapseAll()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("Collapse")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.reloadPage } },
                                  [
                                    _c("v-list-item-title", [_vm._v("Refresh")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var expand = ref.expand
                  var isExpanded = ref.isExpanded
                  return [
                    _c("tr", [
                      _c("td"),
                      _c(
                        "td",
                        { staticClass: "text- d-none d-md-table-cell" },
                        [_vm._v(_vm._s(item.id))]
                      ),
                      _c("td", { staticClass: "text d-none d-md-table-cell" }, [
                        _vm._v(_vm._s(item.datetime))
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.descr))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("v-chip", { attrs: { color: item.datacolor } }, [
                            _vm._v(_vm._s(item.ts))
                          ])
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.period))
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.period_time))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s("mdi-thumb-" + item.active))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return expand(!isExpanded)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    isExpanded
                                      ? "mdi-magnify-minus"
                                      : "mdi-magnify-plus"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.restartMatch(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-restart ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.removeMatch(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-stop ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.rundemo()
                                }
                              }
                            },
                            [_vm._v(" mdi-play-box-outline ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: _vm.outerHeaders.length } },
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.innerHeaders,
                            items: item.rows,
                            "item-key": "id",
                            "hide-default-footer": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.type))
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-right" },
                                        [_vm._v(_vm._s(props.item.name))]
                                      ),
                                      _c(
                                        "td",
                                        {},
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "mdi-thumb-" +
                                                    props.item.active
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  icon: "",
                                                                  color:
                                                                    "blue lighten-2"
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.showContainerLog(
                                                                      props.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-view-headline "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Show Container Log")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }