<template>
  <div class="">
    <v-card>
      <v-dialog v-model="dialogRestart" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to restart this match?<br />
            All client connections will be closed</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeRestart"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="restartMatchConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogRemove" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to remove this match?<br />
            All client connections will be closed</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeRemove"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="removeMatchConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDemo" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want restart demo data?<br />
            Remember to lauch it after UTE-VIT match restart</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDemo">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="rundemoConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        dense
        :headers="outerHeaders"
        :items="matches"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items-per-page="20"
        item-key="id"
        show-expand
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar id="toolbar">
            <v-toolbar-title>
              <span class="px-3">Active Matches - {{ now }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu top :close-on-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list rounded color="primary">
                <v-list-item  @click="expandAll()">
                  <v-list-item-title color="primary">Expand</v-list-item-title>
                </v-list-item>
                <v-list-item @click="collapseAll()">
                  <v-list-item-title color="primary">Collapse</v-list-item-title>
                </v-list-item>
                <v-list-item @click="reloadPage">
                  <v-list-item-title >Refresh</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td></td>
            <td class="text- d-none d-md-table-cell">{{ item.id }}</td>
            <td class="text d-none d-md-table-cell">{{ item.datetime }}</td>
            <td class="text-center">{{ item.descr }}</td>
            <td class="text-center">
              <v-chip :color="item.datacolor">{{ item.ts }}</v-chip>
            </td>
            <td class="text-center">{{ item.period }}</td>
            <td class="text-center">{{ item.period_time }}</td>
            <td class="text-center">
              <v-icon small>{{ `mdi-thumb-${item.active}` }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon class="mr-2" @click="expand(!isExpanded)">
                {{ isExpanded ? "mdi-magnify-minus" : "mdi-magnify-plus" }}
              </v-icon>
              <v-icon class="mr-2" @click="restartMatch(item)">
                mdi-restart
              </v-icon>
              <v-icon class="mr-2" @click="removeMatch(item)">
                mdi-stop
              </v-icon>
              <v-icon class="mr-2" @click="rundemo()">
                mdi-play-box-outline
              </v-icon>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="outerHeaders.length">
            <v-data-table
              :headers="innerHeaders"
              :items="item.rows"
              item-key="id"
              class="elevation-1"
              hide-default-footer
            >
              <!-- I should be able to hide headers and have the parent row checkbox act like the select all headers' checkbox -->
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.type }}</td>
                  <td class="text-xs-right">{{ props.item.name }}</td>
                  <td class="">
                    <v-icon small>{{
                      `mdi-thumb-${props.item.active}`
                    }}</v-icon>
                  </td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          icon
                          color="blue lighten-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            class="mr-2"
                            @click="showContainerLog(props.item)"
                          >
                            mdi-view-headline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Show Container Log</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
export default {
  name: "MatchesDatatable",
  data() {
    return {
      expanded: [],
      singleExpand: false,
      matches: [],
      loading: true,
      options: {},
      dialogRestart: false,
      dialogRemove: false,
      dialogDemo: false,
      editedIndex: -1,
      editedItem: {},
      editedSubIndex: -1,
      editedSubItem: {},
      outerHeaders: [
        {
          text: "Id",
          value: "id",
          align: " d-none d-md-table-cell",
          sortable: false,
        },
        { text: "Date", align: " d-none d-md-table-cell", value: "datetime" },
        { text: "Description", align: "center", value: "descr" },
        { text: "Last Ts", align: "center", value: "ts" },
        { text: "Period", align: "center", value: "period" },
        { text: "Time", align: "center", value: "period_time" },
        { text: "Act", align: "center", value: "active" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      innerHeaders: [
        { text: "Type", value: "type", class: "blue lighten-2" },
        { text: "Name", value: "name", class: "blue lighten-2" },
        { text: "Active", value: "active", class: "blue lighten-2" },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          class: "blue lighten-2",
          sortable: false,
        },
      ],
      refreshTimer: "",
      now: "",
      toggleMenu: false,
      closeOnClick: true,
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
      dialogRestart(val) {
        val || this.closeRestart();
      },
    },
    deep: true,
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
    showMenu() {
      return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    restartMatch(item) {
      this.editedIndex = this.matches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestart = true;
    },
    restartMatchConfirm() {
      // Recupera la lista di tutti i container per i match attivi
      const param = {
        matchid: this.editedItem.id,
        restartFlag: "N",
        containerApiUrl: process.env.VUE_APP_CONTAINER_API_URL,
        localApiUrl: process.env.VUE_APP_API_URL,
        debugFlag: "N",
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/console/restart", param)
        .then((response) => {
          alert("Restarted");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeRestart();
    },
    closeRestart() {
      this.dialogRestart = false;
    },
    removeMatch(item) {
      this.editedIndex = this.matches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRemove = true;
    },
    removeMatchConfirm() {
      // Recupera la lista di tutti i container per i match attivi
      const param = {
        matchid: this.editedItem.id,
        restartFlag: "N",
        containerApiUrl: process.env.VUE_APP_CONTAINER_API_URL,
        localApiUrl: process.env.VUE_APP_API_URL,
        debugFlag: "N",
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/console/remove", param)
        .then((response) => {
          alert("Removed");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeRemove();
    },
    closeRemove() {
      this.dialogRemove = false;
    },
    rundemo(item) {
      this.editedIndex = this.matches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDemo = true;
    },
    rundemoConfirm() {
      // Recupera la lista di tutti i container per i match attivi
      axios
        .post(process.env.VUE_APP_API_URL + "/console/demo")
        .then((response) => {
          alert("Demo Started");
        })
        .catch((err) => {
          alert(err);
        });
      this.closeDemo();
    },
    closeDemo() {
      this.dialogDemo = false;
    },
    showContainerLog(subRowData) {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=1024,height=768`;
      open(
        "/containerlog/" + subRowData.name,
        subRowData.name + " Log",
        params
      );
    },

    expandAll: function() {
      console.log("All expanded.");
      this.$data.expanded = this.$data.matches;
    },
    collapseAll: function() {
      console.log("All collapsed.");
      this.$data.expanded = [];
    },
    async readDataFromAPI() {
      this.now = new Date().toLocaleString();
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      // Recupera la lista di tutti i container per i match attivi
      axios
        .get(process.env.VUE_APP_API_URL + "/console/checkmatch", {
          params: {
            matchid: -1,
            dockerApiUrl: process.env.VUE_APP_DOCKER_API_URL,
            localApiUrl: process.env.VUE_APP_API_URL,
          },
        })
        .then((response) => {
          this.loading = false;
          // Legge in ordine di match e crea la struttura per master-details
          let container = response.data;
          // Creo la funzione di ordinamento
          function GetSortOrder(prop1, prop2, prop3) {
            return function(a, b) {
              if (a[prop1] > b[prop1]) {
                return 1;
              } else if (a[prop1] < b[prop1]) {
                return -1;
              } else if (a[prop2] > b[prop2]) {
                return 1;
              } else if (a[prop2] < b[prop2]) {
                return -1;
              } else if (a[prop3] > b[prop3]) {
                return 1;
              } else if (a[prop3] < b[prop3]) {
                return -1;
              }
              return 0;
            };
          }
          container.sort(GetSortOrder("matchId", "type", "liveId"));
          this.matches = [];
          let match = {};
          let matchRows = [];
          let prevMatchId = -1;
          let matchActive = true;
          for (const item of container) {
            if (item.matchId != prevMatchId) {
              if (prevMatchId >= 0) {
                match["rows"] = matchRows;
                match["active"] = matchActive ? "up" : "down";
                this.matches.push(match);
              }
              match = {
                id: item.matchId,
              };
              matchRows = [];
              prevMatchId = item.matchId;
              matchActive = true;
            }
            // Dettaglio
            if (item.matchDatetime) {
              match["datetime"] = item.matchDatetime;
              match["descr"] = item.matchDescr;
              //Siccome il valore che mi arriva è in timezone UTC devo passare per una date
              const cvtDate = new Date(item.ts);
              match["ts"] = cvtDate.toLocaleString().substring(11, 20);
              match["datacolor"] =
                item.deltaSec > 30
                  ? item.period == "-1"
                    ? "orange"
                    : "red"
                  : "green";
              match["period"] = item.period;
              match["period_time"] = item.period_time;
            }
            matchRows.push({
              type: item.type,
              name: item.name,
              active: item.active ? "up" : "down",
            });
            if (matchActive && !item.active) matchActive = false;
          }
          // Ultimo
          match["rows"] = matchRows;
          match["active"] = matchActive ? "up" : "down";
          this.matches.push(match);

          //console.log(this.matches);

          //this.totalPassengers = response.data.totalPassengers;
          //this.numberOfPages = response.data.totalPages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {
      //console.log(row.postId);
      //this.$emit("setPost", row.postId, row.hometeam, row.awayteam);
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {},
  created() {
    this.readDataFromAPI();
    this.refreshTimer = setInterval(this.readDataFromAPI, 5000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>
